import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Book, Trash2, Upload } from "lucide-react";
import type { LibraryBook } from "@/types/book";

interface BookLibraryProps {
  books: LibraryBook[];
  onBookSelect: (book: LibraryBook) => void;
  onDeleteBook: (bookId: string) => void;
  onUploadClick: () => void;
}

const getRelativeTimeString = (timestamp: number) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = Date.now() - timestamp;

  if (elapsed < msPerMinute) {
    return "just now";
  } else if (elapsed < msPerHour) {
    const mins = Math.round(elapsed / msPerMinute);
    return `${mins} minute${mins !== 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerDay) {
    const hours = Math.round(elapsed / msPerHour);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerMonth) {
    const days = Math.round(elapsed / msPerDay);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const years = Math.round(elapsed / msPerYear);
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
};

export function BookLibrary({ books, onBookSelect, onDeleteBook, onUploadClick }: BookLibraryProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {books.map((book) => (
        <Card key={book.id} className="bg-white/5 border-white/10 backdrop-blur-sm hover:bg-white/10 transition-colors">
          <CardContent className="p-6">
            <div className="flex items-start justify-between gap-2">
              <div className="min-w-0 flex-1">
                <div className="flex items-center gap-2 mb-2">
                  <Book className="flex-shrink-0 w-4 h-4 text-blue-400" />
                  <h3 className="font-medium text-white truncate">{book.name}</h3>
                </div>
                {book.lastRead && (
                  <p className="text-sm text-gray-400">Last read: {getRelativeTimeString(book.lastRead)}</p>
                )}
              </div>
              <div className="flex-shrink-0 flex items-center">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 text-gray-400 hover:text-red-400"
                  onClick={() => onDeleteBook(book.id)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
            <Button
              className="w-full mt-4 bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600"
              onClick={() => onBookSelect(book)}
            >
              Read Now
            </Button>
          </CardContent>
        </Card>
      ))}

      {/* Upload Card */}
      <Card
        className="bg-white/5 border-white/10 border-dashed backdrop-blur-sm hover:bg-white/10 transition-colors group cursor-pointer"
        onClick={onUploadClick}
      >
        <CardContent className="p-6 flex flex-col items-center justify-center h-full min-h-[200px] text-center space-y-4">
          <div className="w-12 h-12 rounded-full bg-blue-500/10 border border-blue-500/20 flex items-center justify-center group-hover:scale-110 transition-transform">
            <Upload className="w-6 h-6 text-blue-400" />
          </div>
          <div>
            <h3 className="font-medium text-white mb-1">Upload your EPUB</h3>
            <p className="text-sm text-gray-400">Click to browse or drag and drop</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
