"use client";

import { useState, useEffect } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowUpRight, Book } from "lucide-react";
import { storage } from "@/lib/storage";
import type { BookHighlight } from "@/types/book";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

interface GroupedNotes {
  [bookId: string]: {
    bookName: string;
    highlights: BookHighlight[];
  };
}

interface NotesOverviewProps {
  onNavigateToBook: (bookId: string, cfiRange: string) => void;
}

export function NotesOverview({ onNavigateToBook }: NotesOverviewProps) {
  const [groupedNotes, setGroupedNotes] = useState<GroupedNotes>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAllNotes = async () => {
      try {
        // Get all books first
        const books = await storage.getAllBooks();
        const notesMap: GroupedNotes = {};

        // For each book, get its highlights with notes
        for (const book of books) {
          const highlights = await storage.getHighlights(book.fileName);
          const highlightsWithNotes = highlights.filter((h) => h.note);

          if (highlightsWithNotes.length > 0) {
            notesMap[book.fileName] = {
              bookName: book.name,
              highlights: highlightsWithNotes,
            };
          }
        }

        setGroupedNotes(notesMap);
      } catch (error) {
        console.error("Failed to load notes:", error);
      } finally {
        setLoading(false);
      }
    };

    loadAllNotes();
  }, []);

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 w-32 bg-white/10 rounded mb-8"></div>
        <div className="space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="h-48 bg-white/5 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  if (Object.keys(groupedNotes).length === 0) {
    return (
      <Card className="bg-white/5 border-white/10 backdrop-blur-sm">
        <CardContent className="p-6">
          <div className="text-center text-gray-400">
            <p>No notes yet</p>
            <p className="text-sm">Select text in any book and add notes to see them here</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {Object.entries(groupedNotes).map(([bookId, { bookName, highlights }]) => (
        <Card key={bookId} className="bg-white/5 border-white/10 backdrop-blur-sm">
          <CardHeader className="pb-4">
            <div className="flex items-center gap-2">
              <Book className="w-4 h-4 text-blue-400" />
              <CardTitle className="text-lg text-white">{bookName}</CardTitle>
            </div>
            <CardDescription>
              {highlights.length} note{highlights.length !== 1 ? "s" : ""}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Accordion type="single" collapsible>
              {highlights.map((highlight, index) => (
                <AccordionItem key={highlight.id} value={highlight.id}>
                  <AccordionTrigger className="hover:no-underline">
                    <div className="flex items-center gap-2">
                      <span className="text-blue-400">#{index + 1}</span>
                      <span className="line-clamp-1 text-left text-gray-300">{highlight.text}</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-4 pt-2">
                      <div className="flex items-start justify-between gap-4">
                        <div className="flex-1 space-y-2">
                          <div className="text-sm text-blue-400 bg-blue-400/10 rounded p-2">{highlight.note}</div>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 text-gray-400 hover:text-blue-400 flex-shrink-0"
                          onClick={() => onNavigateToBook(bookId, highlight.cfiRange)}
                        >
                          <ArrowUpRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
