"use client";

import { Button } from "./ui/button";
import { motion, AnimatePresence } from "framer-motion";
import { BookOpen, Users, ChevronDown } from "lucide-react";
import { useSectionVisibility } from "../hooks/use-section-visibility";
import { Link } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { FloatingMessages } from "./discussion-thread";

export default function Hero() {
  const isFeaturesVisible = useSectionVisibility("built-for-readers");
  const { toast } = useToast();

  return (
    <section id="Home" className="relative min-h-[calc(100vh-76px)] flex items-center overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <FloatingMessages />
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <div className="hero-content max-w-4xl mx-auto text-center">
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-white mb-6">
              Transform Reading into
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                {" "}
                Collaborative Learning
              </span>
            </h1>
          </motion.div>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-gray-400 text-xl mb-8 max-w-2xl mx-auto"
          >
            Create reading groups, share insights, and collaboratively annotate EPUBs. Turn static books into dynamic
            knowledge maps with real-time collaboration.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <Link to="/bookshelf">
              <Button
                size="lg"
                className="bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600 text-white px-8"
              >
                <BookOpen className="mr-2 h-5 w-5" />
                Checkout Bookshelf
              </Button>
            </Link>
            <Button
              size="lg"
              variant="outline"
              className="text-white border-white/20 bg-white/5 backdrop-blur-sm hover:bg-gradient-to-r hover:from-blue-500/20 hover:to-orange-500/20"
              onClick={() => {
                toast({
                  title: "Thanks for your interest!",
                  description: "Sure, Subash is working on it",
                  className: "border-white/10",
                });
              }}
            >
              <Users className="mr-2 h-5 w-5" />
              Keep me posted
            </Button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="mt-8 text-sm text-gray-500"
          >
            Currently in Alpha • Local Storage Only
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {!isFeaturesVisible && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute bottom-12 left-1/2 transform -translate-x-1/2 z-10"
          >
            <motion.div
              animate={{ y: [0, 10, 0] }}
              transition={{ duration: 2, repeat: Number.POSITIVE_INFINITY }}
              className="text-white/70 hover:text-white/90 transition-opacity cursor-pointer"
              onClick={() => document.getElementById("built-for-readers")?.scrollIntoView({ behavior: "smooth" })}
            >
              <ChevronDown className="w-8 h-8" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
