"use client";

import { motion } from "framer-motion";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { useEffect, useState } from "react";
import { Link2, ThumbsUp, CornerDownRight } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useAvailableSpace } from "@/hooks/use-available-space";
import { useToast } from "@/hooks/use-toast";

interface MessageContent {
  book?: string;
  quote?: string;
  page?: string;
  note?: string;
  linkedPage?: string;
  reactions?: number;
  replyTo?: string;
}

interface Message {
  id: string;
  user: {
    name: string;
    avatar: string;
    initials: string;
    gradientFrom: string;
    gradientTo: string;
  };
  content: MessageContent;
}

interface Thread {
  id: string;
  position: {
    top: string;
    left?: string;
    right?: string;
  };
  messages: Message[];
}
const threads: Thread[] = [
  {
    id: uuidv4(),
    position: { top: "17%", left: "2%" },
    messages: [
      {
        id: uuidv4(),
        user: {
          name: "Sophia Müller",
          avatar: "https://v0.dev/placeholder-avatar.jpg",
          initials: "SM",
          gradientFrom: "from-indigo-400",
          gradientTo: "to-purple-400",
        },
        content: {
          book: "The Emperor of All Maladies",
          quote: "The fundamental biological mechanisms that drive cancer are only now being understood...",
          page: "127",
          note: "The parallels between cancer cell signaling and normal cell development are fascinating. It's like studying a corrupted version of our growth pathways 🧬",
          reactions: 3,
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Henrik Andersson",
          avatar: "https://v0.dev/placeholder-avatar-2.jpg",
          initials: "HA",
          gradientFrom: "from-emerald-400",
          gradientTo: "to-teal-400",
        },
        content: {
          note: "Absolutely! Have you seen the recent research on p53 mutations? The way it connects to the DNA damage response pathway is incredible.",
          linkedPage: "245",
          reactions: 2,
          replyTo: "Sophia Müller",
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Pietro Romano",
          avatar: "https://v0.dev/placeholder-avatar.jpg",
          initials: "PR",
          gradientFrom: "from-indigo-400",
          gradientTo: "to-purple-400",
        },
        content: {
          note: "Yes! And it explains why certain cancers are more resistant to chemotherapy. The molecular biology behind treatment resistance is mind-blowing 🔬",
          reactions: 4,
          replyTo: "Henrik Andersson",
        },
      },
    ],
  },
  {
    id: uuidv4(),
    position: { top: "11%", right: "2%" },
    messages: [
      {
        id: uuidv4(),
        user: {
          name: "Marie Dubois",
          avatar: "https://v0.dev/placeholder-avatar-3.jpg",
          initials: "MD",
          gradientFrom: "from-rose-400",
          gradientTo: "to-pink-400",
        },
        content: {
          book: "Immunobiology",
          quote:
            "The adaptive immune system has an almost infinite capacity to recognize specific structures through genetic recombination.",
          page: "156",
          note: "The complexity of our immune system's memory capabilities is incredible. T-cell education in the thymus is like neural network training! 🦠",
          reactions: 5,
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Aleksander Kowalski",
          avatar: "https://v0.dev/placeholder-avatar-4.jpg",
          initials: "AK",
          gradientFrom: "from-blue-400",
          gradientTo: "to-cyan-400",
        },
        content: {
          note: "This reminds me of how mRNA vaccines work - they're essentially programming our immune system with specific instructions 💉",
          reactions: 3,
          replyTo: "Marie Dubois",
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Elena Popov",
          avatar: "https://v0.dev/placeholder-avatar-3.jpg",
          initials: "EP",
          gradientFrom: "from-rose-400",
          gradientTo: "to-pink-400",
        },
        content: {
          note: "Exactly! The way we've harnessed this natural process for vaccines is revolutionary. Nature's code being used to protect us 🧪",
          reactions: 4,
          replyTo: "Aleksander Kowalski",
        },
      },
    ],
  },
  {
    id: uuidv4(),
    position: { top: "57%", left: "65%" },
    messages: [
      {
        id: uuidv4(),
        user: {
          name: "Klaus Schmidt",
          avatar: "https://v0.dev/placeholder-avatar-7.jpg",
          initials: "KS",
          gradientFrom: "from-green-400",
          gradientTo: "to-emerald-400",
        },
        content: {
          book: "Principles of Neural Science",
          quote: "Synaptic plasticity is the fundamental mechanism by which the nervous system adapts to experience.",
          page: "312",
          note: "The brain's ability to rewire itself is remarkable. Each new connection represents a potential memory or skill! 🧠",
          reactions: 4,
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Margot van der Berg",
          avatar: "https://v0.dev/placeholder-avatar-8.jpg",
          initials: "MB",
          gradientFrom: "from-red-400",
          gradientTo: "to-rose-400",
        },
        content: {
          note: "And the implications for neurological rehabilitation are huge. I just read about novel approaches using neuroplasticity for stroke recovery.",
          linkedPage: "428",
          reactions: 5,
          replyTo: "Klaus Schmidt",
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "François Laurent",
          avatar: "https://v0.dev/placeholder-avatar-7.jpg",
          initials: "FL",
          gradientFrom: "from-green-400",
          gradientTo: "to-emerald-400",
        },
        content: {
          note: "Fascinating! It's amazing how understanding these mechanisms leads directly to better therapeutic approaches 🏥",
          reactions: 3,
          replyTo: "Margot van der Berg",
        },
      },
    ],
  },
  {
    id: uuidv4(),
    position: { top: "60%", left: "20%" },
    messages: [
      {
        id: uuidv4(),
        user: {
          name: "Ada Chen",
          avatar: "https://v0.dev/placeholder-avatar-5.jpg",
          initials: "AC",
          gradientFrom: "from-violet-400",
          gradientTo: "to-indigo-400",
        },
        content: {
          book: "Introduction to Algorithms",
          quote:
            "A dynamic-programming approach runs in polynomial time when the number of distinct subproblems involved is polynomial in the input size and we can solve each such subproblem in polynomial time.",
          page: "359",
          note: "The elegance of dynamic programming is mind-blowing! Breaking down complex problems into overlapping subproblems is like discovering a mathematical fractal 🧮",
          reactions: 6,
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Miguel Torres",
          avatar: "https://v0.dev/placeholder-avatar-6.jpg",
          initials: "MT",
          gradientFrom: "from-amber-400",
          gradientTo: "to-orange-400",
        },
        content: {
          note: "Totally! Just used this approach for a graph optimization problem. The memoization pattern is surprisingly similar to caching in distributed systems.",
          linkedPage: "384",
          reactions: 4,
          replyTo: "Ada Chen",
        },
      },
      {
        id: uuidv4(),
        user: {
          name: "Liam O'Connor",
          avatar: "https://v0.dev/placeholder-avatar-9.jpg",
          initials: "LC",
          gradientFrom: "from-cyan-400",
          gradientTo: "to-blue-400",
        },
        content: {
          note: "And once you understand the pattern, you start seeing DP opportunities everywhere! Just like how Redis uses memoization internally 🚀",
          reactions: 5,
          replyTo: "Miguel Torres",
        },
      },
    ],
  },
];

export function FloatingMessages() {
  const [visibleThreads, setVisibleThreads] = useState<Thread[]>([]);
  const hasSpace = useAvailableSpace(".hero-content");
  const { toast } = useToast();

  useEffect(() => {
    if (!hasSpace) {
      // Show toast immediately on mount for small screens
      const timer = setTimeout(() => {
        toast({
          title: "✨ Best on Desktop",
          description: (
            <p className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
              For the full interactive experience, visit on a larger screen
            </p>
          ),
          className: "border-white/10 backdrop-blur-md animate-in slide-in-from-bottom-full duration-500", // Updated className
        });
      }, 500); // Small delay to ensure smooth animation after page load

      return () => clearTimeout(timer);
    }
  }, [hasSpace, toast]);

  useEffect(() => {
    if (!hasSpace) {
      setVisibleThreads([]);
      return;
    }

    const showThreads = async () => {
      for (let i = 0; i < threads.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, i * 600 + 1000));
        setVisibleThreads((prev) => [...prev, threads[i]]);
      }
    };
    showThreads();
  }, [hasSpace]);

  if (!hasSpace) return null;

  const renderMessageContent = (content: MessageContent) => {
    return (
      <div className="space-y-2">
        {content.replyTo && (
          <div className="flex items-center gap-2 text-[10px] text-white/40 mb-1">
            <CornerDownRight className="w-3 h-3" />
            <span>Replying to {content.replyTo}</span>
          </div>
        )}
        {content.book && (
          <div className="flex items-center gap-2 text-[10px] text-white/50">
            <Link2 className="w-3 h-3" />
            <span className="hover:text-white/70 transition-colors cursor-pointer">
              {content.book} {content.page && `p.${content.page}`}
            </span>
          </div>
        )}
        {content.quote && <div className="pl-2 border-l-2 border-white/10 italic text-white/60">"{content.quote}"</div>}
        <div className="space-y-1.5">
          {content.note && (
            <div className="text-white/70">
              {content.note}
              {content.linkedPage && (
                <span className="inline-flex items-center gap-1 text-[10px] text-white/50 ml-1 hover:text-white/70 transition-colors cursor-pointer">
                  <Link2 className="w-3 h-3" />
                  p.{content.linkedPage}
                </span>
              )}
            </div>
          )}
          {content.reactions && (
            <div className="flex items-center gap-1.5">
              <div className="flex items-center gap-1 text-[10px] text-white/50 bg-white/5 px-2 py-1 rounded-full">
                <ThumbsUp className="w-3 h-3" />
                <span>{content.reactions}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {visibleThreads.map((thread) => (
        <motion.div
          key={thread.id}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="absolute w-[300px] max-w-[25vw] pointer-events-none"
          style={{
            top: thread.position.top,
            left: thread.position.left,
            right: thread.position.right,
          }}
        >
          <div className="space-y-2">
            {thread.messages.map((message, messageIndex) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: messageIndex * 0.5,
                  duration: 0.8,
                  type: "spring",
                  stiffness: 80,
                  damping: 15,
                }}
                className={message.content.replyTo ? "ml-4" : ""}
              >
                <div className="flex gap-3 p-3">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      delay: messageIndex * 0.5 + 0.2,
                      type: "spring",
                      stiffness: 200,
                      damping: 15,
                    }}
                  >
                    <Avatar className="w-7 h-7 border border-white/5 ring-1 ring-offset-2 ring-offset-black/50 ring-white/10">
                      <AvatarImage src={message.user.avatar} />
                      <AvatarFallback
                        className={`bg-gradient-to-r ${message.user.gradientFrom} ${message.user.gradientTo} text-white/90 text-xs font-medium opacity-80`}
                      >
                        {message.user.initials}
                      </AvatarFallback>
                    </Avatar>
                  </motion.div>
                  <div className="flex-1 space-y-1">
                    <motion.div
                      className="flex items-center gap-2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: messageIndex * 0.5 + 0.3 }}
                    >
                      <span className="text-xs font-medium text-white/80">{message.user.name}</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </motion.div>
                    <motion.div
                      className="p-2.5 rounded-lg bg-black/40 border border-white/[0.05] text-white/70 backdrop-blur-md
                        bg-gradient-to-r from-white/[0.02] to-white/[0.01] text-xs leading-relaxed"
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: messageIndex * 0.5 + 0.4 }}
                    >
                      {renderMessageContent(message.content)}
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      ))}
    </>
  );
}

export default function DiscussionThread() {
  const hasSpace = useAvailableSpace(".hero-content");

  if (!hasSpace) return null;

  return (
    <div className="absolute inset-0 pointer-events-none">
      <FloatingMessages />
    </div>
  );
}
