"use client";

import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "@/lib/storage";

export function useBookManager() {
  const [books, setBooks] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadBooks = useCallback(async () => {
    try {
      const allBooks = await storage.getAllBooks();
      setBooks(allBooks);
    } catch (error) {
      console.error("Failed to load books:", error);
    }
  }, []);

  const initializeLibrary = useCallback(async () => {
    try {
      setLoading(true);
      // Check if default book exists
      const defaultBook = await storage.getBook("alice.epub");

      if (!defaultBook) {
        // Load the default book from public directory
        const response = await fetch("/alice.epub");
        if (!response.ok) throw new Error("Failed to load default book");

        const content = await response.arrayBuffer();
        await storage.storeBook({
          fileName: "alice.epub",
          name: "Alice in Wonderland",
          content,
          createdAt: new Date(),
        });
      }

      // Load all books including the newly added default book
      await loadBooks();
    } catch (error) {
      console.error("Failed to initialize library:", error);
    } finally {
      setLoading(false);
    }
  }, [loadBooks]);

  const addBook = async (file: File) => {
    try {
      const content = await file.arrayBuffer();
      await storage.storeBook({
        fileName: file.name,
        name: file.name.replace(".epub", ""),
        content,
        createdAt: new Date(),
      });
      await loadBooks();
    } catch (error) {
      console.error("Failed to add book:", error);
      throw error;
    }
  };

  const deleteBook = async (fileName: string) => {
    if (fileName === "alice.epub") {
      alert("Cannot delete the default book");
      return;
    }

    try {
      await storage.deleteBook(fileName);
      await loadBooks();
    } catch (error) {
      console.error("Failed to delete book:", error);
      throw error;
    }
  };

  const openBook = async (fileName: string) => {
    try {
      const book = await storage.getBook(fileName);
      if (!book) throw new Error("Book not found");
      localStorage.setItem("lastAccessedBook", fileName);
      navigate("/reader");
    } catch (error) {
      console.error("Failed to open book:", error);
      throw error;
    }
  };

  useEffect(() => {
    initializeLibrary();
  }, [initializeLibrary]);

  return {
    books,
    loading,
    addBook,
    deleteBook,
    openBook,
  };
}
