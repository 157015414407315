"use client";

import type React from "react";
import { useRef, Suspense, lazy, useState } from "react";
import { BookLibrary } from "@/components/book-library";
import { NotesOverview } from "@/components/notes-overview";
import Navbar from "@/components/navbar";
import { useBookManager } from "@/hooks/use-book-manager";
import type { LibraryBook } from "@/types/book";
import { HighlightTrail } from "@/components/ui/highlight-trail";

const SparklesCore = lazy(() => import("../components/sparkles").then((mod) => ({ default: mod.SparklesCore })));

export default function BookShelf() {
  const { books, loading, addBook, deleteBook, openBook } = useBookManager();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !file.name.toLowerCase().endsWith(".epub")) {
      alert("Please select a valid EPUB file");
      return;
    }

    try {
      await addBook(file);
    } catch (error) {
      console.error("Failed to upload book:", error);
      alert("Failed to upload book. Please try again.");
    }
  };

  const handleDeleteBook = async (fileName: string) => {
    if (fileName === "alice.epub") {
      alert("Cannot delete the default book");
      return;
    }

    try {
      await deleteBook(fileName);
    } catch (error) {
      console.error("Failed to delete book:", error);
      alert("Failed to delete book. Please try again.");
    }
  };

  const handleBookSelect = async (book: LibraryBook) => {
    try {
      await openBook(book.id);
    } catch (error) {
      console.error("Failed to open book:", error);
      alert("Failed to open book. Please try again.");
    }
  };

  const handleNavigateToNote = async (bookId: string, cfiRange: string) => {
    try {
      await openBook(bookId);
      localStorage.setItem(`epub-location-${bookId}`, cfiRange);
    } catch (error) {
      console.error("Failed to navigate to note:", error);
      alert("Failed to navigate to note. Please try again.");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  if (loading) {
    return (
      <main className="min-h-screen bg-black/[0.98] antialiased bg-grid-white/[0.02] text-white relative overflow-hidden custom-cursor">
        <div className="h-full w-full absolute inset-0 z-0">
          <HighlightTrail />
          <Suspense fallback={null}>
            <SparklesCore
              id="tsparticlesfullpage"
              background="transparent"
              minSize={0.8}
              maxSize={1.8}
              particleDensity={120}
              className="w-full h-full"
            />
          </Suspense>
        </div>
        <div className="relative z-10">
          <Navbar showUpload onUploadClick={handleUploadClick} />
          <div className="max-w-4xl mx-auto p-8 pt-28">
            <div className="animate-pulse">
              <div className="h-8 w-32 bg-white/10 rounded mb-8"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="h-48 bg-white/5 rounded-lg"></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  const libraryBooks: LibraryBook[] = books.map((book) => ({
    id: book.fileName,
    name: book.name,
    lastRead: book.createdAt.getTime(),
  }));

  return (
    <main className="min-h-screen bg-black/[0.98] antialiased bg-grid text-white relative overflow-hidden custom-cursor">
      <div className="h-full w-full absolute inset-0 z-0">
        <HighlightTrail />
        <Suspense fallback={null}>
          <SparklesCore
            id="tsparticlesfullpage"
            background="transparent"
            minSize={0.8}
            maxSize={1.8}
            particleDensity={120}
            className="w-full h-full"
          />
        </Suspense>
      </div>

      <div className="relative z-10">
        <Navbar
          showUpload={!isMobileMenuOpen}
          onUploadClick={handleUploadClick}
          onMobileMenuChange={setIsMobileMenuOpen}
        />
        <div className={isMobileMenuOpen ? "hidden md:block" : "block"}>
          <div className="max-w-4xl mx-auto p-8 pt-28">
            <div className="space-y-12">
              <section>
                <div className="flex items-center justify-between mb-8">
                  <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                    Your Books
                  </h1>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".epub"
                    onChange={handleFileUpload}
                    className="hidden"
                    id="epub-upload"
                  />
                </div>

                <BookLibrary
                  books={libraryBooks}
                  onBookSelect={handleBookSelect}
                  onDeleteBook={handleDeleteBook}
                  onUploadClick={handleUploadClick}
                />
              </section>

              <section>
                <h2 className="text-2xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                  Your Notes
                </h2>
                <NotesOverview onNavigateToBook={handleNavigateToNote} />
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
