"use client";

import { Button } from "./ui/button";
import { PenLine, Menu, Upload } from "lucide-react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MobileMenu } from "./mobile-menu";

interface NavbarProps {
  showUpload?: boolean;
  showBookshelf?: boolean;
  onUploadClick?: () => void;
  onMobileMenuChange?: (isOpen: boolean) => void;
}

export default function Navbar({ showUpload, showBookshelf, onUploadClick, onMobileMenuChange }: NavbarProps) {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = (isOpen: boolean) => {
    setIsMobileMenuOpen(isOpen);
    onMobileMenuChange?.(isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial scroll position

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Check if we need to scroll to a section
    const scrollTo = localStorage.getItem("scrollTo");
    if (scrollTo && window.location.pathname === "/") {
      localStorage.removeItem("scrollTo"); // Clear the stored section
      // Small delay to ensure the page is loaded
      setTimeout(() => {
        scrollToSection(scrollTo);
      }, 100);
    }
  }, []);

  const scrollToSection = (sectionId: string) => {
    // If we're not on the home page, store the section to scroll to and navigate home
    if (window.location.pathname !== "/") {
      localStorage.setItem("scrollTo", sectionId);
      window.location.href = "/";
      return;
    }

    // If we are on home page, scroll to section
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 80; // Account for fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed top-0 left-0 right-0 z-50 w-full flex items-center justify-between px-6 py-4 border-b transition-colors duration-200 cursor-default ${
        isScrolled ? "border-white/10 bg-black/80 backdrop-blur-md" : "border-transparent bg-black/95 backdrop-blur-sm"
      }`}
    >
      <Link to="/" className="flex items-center space-x-3">
        <div className="relative w-10 h-10 flex items-center justify-center">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-orange-500 rounded-xl opacity-20 blur-sm" />
          <PenLine className="w-6 h-6 text-white relative z-10" strokeWidth={2.5} />
        </div>
        <span className="text-white font-bold text-2xl tracking-tight">
          Annotate
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">.club</span>
        </span>
      </Link>

      {!showUpload && !showBookshelf && (
        <>
          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => scrollToSection("Home")}
              className="text-gray-300 hover:text-white transition-colors relative group"
            >
              Home
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-blue-500 to-orange-500 transition-all group-hover:w-full" />
            </button>
            <button
              onClick={() => scrollToSection("built-for-readers")}
              className="text-gray-300 hover:text-white transition-colors relative group"
            >
              Features
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-blue-500 to-orange-500 transition-all group-hover:w-full" />
            </button>
            <button
              onClick={() => scrollToSection("research-backed")}
              className="text-gray-300 hover:text-white transition-colors relative group"
            >
              Research
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-blue-500 to-orange-500 transition-all group-hover:w-full" />
            </button>
            <button
              onClick={() => navigate("/pricing")}
              className="text-gray-300 hover:text-white transition-colors relative group"
            >
              Pricing
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-blue-500 to-orange-500 transition-all group-hover:w-full" />
            </button>
          </div>

          <div className="hidden md:flex items-center">
            <Link to="/bookshelf">
              <Button className="bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600 text-white">
                Try Demo
              </Button>
            </Link>
          </div>
        </>
      )}

      {showUpload && (
        <div className="hidden md:flex items-center">
          <Button
            onClick={onUploadClick}
            className="group relative px-4 py-2 text-white/90 hover:text-white transition-colors border border-white/10 bg-white/5 hover:bg-white/10"
          >
            <div className="flex items-center space-x-2">
              <Upload className="w-4 h-4" />
              <span className="text-sm font-medium">Upload EPUB</span>
            </div>
          </Button>
        </div>
      )}

      {showBookshelf && (
        <div className="flex items-center gap-4">
          <Link to="/">
            <Button
              variant="outline"
              className="text-white border-white/20 bg-white/5 hover:bg-white/10 hover:text-white"
            >
              Home
            </Button>
          </Link>
          <Link to="/bookshelf">
            <Button
              variant="outline"
              className="text-white border-white/20 bg-white/5 hover:bg-white/10 hover:text-white"
            >
              Back to Library
            </Button>
          </Link>
        </div>
      )}

      <Button variant="ghost" size="icon" className="md:hidden text-white" onClick={() => handleMobileMenuToggle(true)}>
        <Menu className="w-6 h-6" />
      </Button>
      {!showUpload && (
        <MobileMenu
          isOpen={isMobileMenuOpen}
          onClose={() => handleMobileMenuToggle(false)}
          showHomeLink={showBookshelf}
          hideUpload={showBookshelf}
        />
      )}
    </motion.nav>
  );
}
