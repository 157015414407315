"use client";

import { useState, useEffect, useRef } from "react";

interface MousePosition {
  x: number;
  y: number;
  absoluteY: number;
  isScrolling: boolean;
}

export const useMousePosition = (): MousePosition => {
  const [position, setPosition] = useState<MousePosition>({
    x: 0,
    y: 0,
    absoluteY: 0,
    isScrolling: false,
  });
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();
  const lastKnownPosition = useRef({ x: 0, y: 0, absoluteY: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const newPosition = {
        x: event.clientX,
        y: event.clientY,
        absoluteY: event.clientY + window.scrollY,
        isScrolling: position.isScrolling,
      };
      lastKnownPosition.current = newPosition;
      setPosition(newPosition);
    };

    const handleScroll = () => {
      setPosition((prev) => ({ ...prev, isScrolling: true }));

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      scrollTimeoutRef.current = setTimeout(() => {
        // When scroll stops, update position with last known mouse coordinates
        setPosition({
          x: lastKnownPosition.current.x,
          y: lastKnownPosition.current.y,
          absoluteY: lastKnownPosition.current.y + window.scrollY,
          isScrolling: false,
        });
      }, 150); // Adjust timeout as needed
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [position.isScrolling]);

  return position;
};
