"use client";

import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useMousePosition } from "@/hooks/use-mouse-position";

interface Point {
  x: number;
  y: number;
  age: number;
}

export function HighlightTrail() {
  const mousePosition = useMousePosition();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const points = useRef<Point[]>([]);
  const rafRef = useRef<number>();
  const lastPointRef = useRef<{ x: number; y: number } | null>(null);
  const lastMousePosition = useRef({ x: 0, y: 0, absoluteY: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set up canvas
    const dpr = window.devicePixelRatio || 1;
    const updateCanvasSize = () => {
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;
      ctx.scale(dpr, dpr);
    };

    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);

    // Animation
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Get canvas position relative to viewport
      const rect = canvas.getBoundingClientRect();

      // Calculate position relative to the current viewport
      const x = mousePosition.x - rect.left;
      const y = mousePosition.y; // Use viewport-relative Y position directly

      // Check for real mouse movement
      const hasMouseMoved =
        Math.abs(mousePosition.x - lastMousePosition.current.x) > 0 ||
        Math.abs(mousePosition.y - lastMousePosition.current.y) > 0;

      // Clear points when scrolling starts
      if (mousePosition.isScrolling) {
        points.current = [];
        lastPointRef.current = null;
      }

      // Add points only when not scrolling and mouse has moved
      if (mousePosition.x !== 0 && mousePosition.y !== 0 && !mousePosition.isScrolling) {
        if (
          hasMouseMoved &&
          (!lastPointRef.current || Math.hypot(lastPointRef.current.x - x, lastPointRef.current.y - y) > 5)
        ) {
          // If this is the first point after scrolling, clear previous points
          if (!lastPointRef.current) {
            points.current = [];
          }

          points.current.push({ x, y, age: 0 });
          lastPointRef.current = { x, y };
        }

        // Update last known position
        lastMousePosition.current = {
          x: mousePosition.x,
          y: mousePosition.y,
          absoluteY: mousePosition.absoluteY,
        };
      }

      // Update and draw points with longer trail
      points.current = points.current
        .filter((point) => point.age < 60) // Increased from 40 to 60 for longer trail
        .map((point) => ({ ...point, age: point.age + 1 }));

      // Draw trail
      if (points.current.length > 1) {
        ctx.beginPath();
        ctx.moveTo(points.current[0].x, points.current[0].y);

        // Create smooth curve through points
        for (let i = 1; i < points.current.length - 1; i++) {
          const xc = (points.current[i].x + points.current[i + 1].x) / 2;
          const yc = (points.current[i].y + points.current[i + 1].y) / 2;
          ctx.quadraticCurveTo(points.current[i].x, points.current[i].y, xc, yc);
        }

        // Draw gradient trail with pastel highlighter colors
        const gradient = ctx.createLinearGradient(
          points.current[0].x,
          points.current[0].y,
          points.current[points.current.length - 1].x,
          points.current[points.current.length - 1].y,
        );

        // Pastel highlighter gradient
        gradient.addColorStop(0, "rgba(255, 229, 180, 0.6)"); // Peach
        gradient.addColorStop(0.2, "rgba(200, 183, 232, 0.5)"); // Lilac
        gradient.addColorStop(0.4, "rgba(176, 224, 230, 0.5)"); // Powder blue
        gradient.addColorStop(0.6, "rgba(255, 182, 193, 0.5)"); // Light pink
        gradient.addColorStop(0.8, "rgba(221, 255, 187, 0.5)"); // Light mint
        gradient.addColorStop(1, "rgba(255, 218, 185, 0.4)"); // Peach fade

        ctx.strokeStyle = gradient;
        ctx.lineWidth = 8;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.stroke();
      }

      rafRef.current = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [mousePosition]);

  return (
    <motion.canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none z-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    />
  );
}
