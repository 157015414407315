"use client";

import { motion } from "framer-motion";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { MessageSquare, Network, BookMarked, Link2 } from "lucide-react";

export function LeftFeatureIllustrations() {
  return (
    <div className="absolute left-8 top-1/2 -translate-y-1/2 w-[340px]">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <Card className="bg-black/20 border-white/10 backdrop-blur-[2px]">
          <CardContent className="p-4">
            <div className="flex items-center gap-2 mb-3">
              <MessageSquare className="w-4 h-4 text-blue-400" />
              <span className="text-sm text-white/70">Study Group Q&A</span>
            </div>
            <ScrollArea className="h-[400px] rounded-md border border-white/5 p-2">
              <div className="space-y-4">
                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src="https://v0.dev/placeholder-avatar.jpg" />
                    <AvatarFallback>JL</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-white/80">Julia Lee</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </div>
                    <div className="text-xs text-white/70 mt-1 p-2.5 rounded-lg bg-black/20 border border-white/[0.05]">
                      <div className="flex items-center gap-2 text-[10px] text-white/50 mb-1">
                        <Link2 className="w-3 h-3 text-blue-400" />
                        <span className="hover:text-white/70 transition-colors cursor-pointer">
                          Chapter 4: Neural Plasticity
                        </span>
                      </div>
                      <div className="pl-2 border-l-2 border-white/10 italic text-white/60 mb-2">
                        "The fundamental mechanisms of synaptic plasticity vary across different brain regions..."
                      </div>
                      This section on plasticity mechanisms is fascinating! The way LTP works in the hippocampus vs the
                      neocortex shows such interesting regional specialization 🧠
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-2.jpg" />
                    <AvatarFallback>RC</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-white/80">Dr. Robert Chen</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </div>
                    <div className="text-xs text-white/70 mt-1 p-2.5 rounded-lg bg-black/20 border border-white/[0.05]">
                      Absolutely! And if you look at{" "}
                      <span className="text-blue-400 hover:underline cursor-pointer">page 128</span>, there's a great
                      diagram showing how these mechanisms evolved differently. The molecular cascades are remarkably
                      conserved despite the functional differences.
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-3.jpg" />
                    <AvatarFallback>SP</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-white/80">Sarah Park, PhD</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </div>
                    <div className="text-xs text-white/70 mt-1 p-2.5 rounded-lg bg-black/20 border border-white/[0.05]">
                      The connection to memory formation on{" "}
                      <span className="text-blue-400 hover:underline cursor-pointer">page 156</span> is mind-blowing
                      too. The way these regional differences contribute to different types of memory storage 🤯
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-4.jpg" />
                    <AvatarFallback>MT</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-white/80">Michael Torres</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </div>
                    <div className="text-xs text-white/70 mt-1 p-2.5 rounded-lg bg-black/20 border border-white/[0.05]">
                      <div className="flex items-center gap-2 text-[10px] text-white/50 mb-1">
                        <Link2 className="w-3 h-3 text-blue-400" />
                        <span className="hover:text-white/70 transition-colors cursor-pointer">Figure 4.8 • p.132</span>
                      </div>
                      This figure showing the NMDA receptor's role in coincidence detection is crucial for understanding
                      the whole process. The calcium signaling cascade is like a molecular AND gate! 🔬
                    </div>
                  </div>
                </div>

                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-5.jpg" />
                    <AvatarFallback>AK</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-white/80">Anna Kim</span>
                      <span className="text-[10px] text-white/40">just now</span>
                    </div>
                    <div className="text-xs text-white/70 mt-1 p-2.5 rounded-lg bg-black/20 border border-white/[0.05]">
                      And don't miss the clinical applications in{" "}
                      <span className="text-blue-400 hover:underline cursor-pointer">Chapter 8</span>! The implications
                      for treating memory disorders are incredible. The new drug targets they mention could
                      revolutionize how we approach cognitive enhancement 💊
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
}

export function RightFeatureIllustrations() {
  return (
    <div className="absolute right-8 top-1/2 -translate-y-1/2 space-y-8 w-[250px]">
      {/* Reading Group */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Card className="bg-black/20 border-white/10 backdrop-blur-[2px]">
          <CardContent className="p-4">
            <div className="flex items-center gap-2 mb-3">
              <Network className="w-4 h-4 text-orange-400" />
              <span className="text-sm text-white/70">Active Study Group</span>
            </div>
            <div className="flex -space-x-2">
              <Avatar className="border-2 border-black w-8 h-8">
                <AvatarImage src="https://v0.dev/placeholder-avatar.jpg" />
                <AvatarFallback>JL</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-black w-8 h-8">
                <AvatarImage src="https://v0.dev/placeholder-avatar-2.jpg" />
                <AvatarFallback>RC</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-black w-8 h-8">
                <AvatarImage src="https://v0.dev/placeholder-avatar-3.jpg" />
                <AvatarFallback>MT</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-black w-8 h-8">
                <AvatarImage src="https://v0.dev/placeholder-avatar-4.jpg" />
                <AvatarFallback>SP</AvatarFallback>
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      {/* Citation Vault */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <Card className="bg-black/20 border-white/10 backdrop-blur-[2px]">
          <CardContent className="p-4">
            <div className="flex items-center gap-2 mb-3">
              <BookMarked className="w-4 h-4 text-orange-400" />
              <span className="text-sm text-white/70">Citation Vault</span>
            </div>
            <div className="space-y-3">
              <div className="p-2.5 rounded-lg bg-black/20 border border-white/[0.05] space-y-2 hover:bg-black/30 transition-colors cursor-pointer group">
                <div className="flex items-center gap-2">
                  <Link2 className="w-3 h-3 text-blue-400" />
                  <span className="text-xs text-white/70">Nature Neuroscience</span>
                  <span className="text-[10px] text-white/40">2023</span>
                </div>
                <div className="text-[10px] text-white/70 font-medium">
                  "Regional Specificity in Synaptic Plasticity: New Insights from High-Resolution Imaging"
                </div>
                <div className="text-[10px] text-white/50 line-clamp-2">
                  Breakthrough study revealing distinct molecular mechanisms of plasticity across brain regions, with
                  implications for memory formation and learning.
                </div>
                <div className="flex items-center gap-2 mt-2 pt-2 border-t border-white/5">
                  <Avatar className="w-4 h-4">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-2.jpg" />
                    <AvatarFallback>RC</AvatarFallback>
                  </Avatar>
                  <span className="text-[10px] text-white/40">Added by Dr. Robert Chen</span>
                </div>
              </div>

              <div className="p-2.5 rounded-lg bg-black/20 border border-white/[0.05] space-y-2 hover:bg-black/30 transition-colors cursor-pointer group">
                <div className="flex items-center gap-2">
                  <Link2 className="w-3 h-3 text-blue-400" />
                  <span className="text-xs text-white/70">Neuron</span>
                  <span className="text-[10px] text-white/40">2023</span>
                </div>
                <div className="text-[10px] text-white/70 font-medium">
                  "NMDA Receptor-Dependent Plasticity Mechanisms in Learning and Memory"
                </div>
                <div className="text-[10px] text-white/50 line-clamp-2">
                  Comprehensive review of molecular mechanisms underlying synaptic plasticity, focusing on the role of
                  NMDA receptors in memory formation.
                </div>
                <div className="flex items-center gap-2 mt-2 pt-2 border-t border-white/5">
                  <Avatar className="w-4 h-4">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-3.jpg" />
                    <AvatarFallback>SP</AvatarFallback>
                  </Avatar>
                  <span className="text-[10px] text-white/40">Added by Sarah Park, PhD</span>
                </div>
              </div>

              <div className="p-2.5 rounded-lg bg-black/20 border border-white/[0.05] space-y-2 hover:bg-black/30 transition-colors cursor-pointer group">
                <div className="flex items-center gap-2">
                  <Link2 className="w-3 h-3 text-blue-400" />
                  <span className="text-xs text-white/70">Science</span>
                  <span className="text-[10px] text-white/40">2023</span>
                </div>
                <div className="text-[10px] text-white/70 font-medium">
                  "Therapeutic Applications of Neural Plasticity in Cognitive Enhancement"
                </div>
                <div className="text-[10px] text-white/50 line-clamp-2">
                  Clinical perspectives on translating plasticity research into therapeutic interventions for cognitive
                  disorders and memory enhancement.
                </div>
                <div className="flex items-center gap-2 mt-2 pt-2 border-t border-white/5">
                  <Avatar className="w-4 h-4">
                    <AvatarImage src="https://v0.dev/placeholder-avatar-5.jpg" />
                    <AvatarFallback>AK</AvatarFallback>
                  </Avatar>
                  <span className="text-[10px] text-white/40">Added by Anna Kim</span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
}
