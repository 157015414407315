"use client";

import { useState, useEffect, useRef } from "react";

export const HERO_CONTENT_SELECTOR = ".hero-content";

export function useAvailableSpace(contentSelector: string = HERO_CONTENT_SELECTOR) {
  const [hasSpace, setHasSpace] = useState(false);
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const checkSpace = () => {
      // First check viewport dimensions
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // If viewport is smaller than minimum dimensions, return false immediately
      if (viewportWidth < 1550 || viewportHeight < 1000) {
        setHasSpace(false);
        return;
      }

      // Then check content width if viewport dimensions are sufficient
      const content = document.querySelector(contentSelector);
      if (!content) {
        setHasSpace(false);
        return;
      }

      const contentWidth = content.getBoundingClientRect().width;
      const minRequiredSpace = 300; // Minimum width needed for floating messages
      const availableSpace = (viewportWidth - contentWidth) / 2;

      setHasSpace(availableSpace >= minRequiredSpace);
    };

    // Initial check
    checkSpace();

    // Set up ResizeObserver for the content
    observerRef.current = new ResizeObserver(checkSpace);
    const content = document.querySelector(contentSelector);
    if (content) {
      observerRef.current.observe(content);
    }

    // Watch for window resizes
    window.addEventListener("resize", checkSpace);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener("resize", checkSpace);
    };
  }, [contentSelector]);

  return hasSpace;
}
