"use client";

import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { X, PenLine } from "lucide-react";

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  showHomeLink?: boolean;
  hideUpload?: boolean;
}

export function MobileMenu({ isOpen, onClose, hideUpload }: MobileMenuProps) {
  const navigate = useNavigate();
  const menuVariants = {
    closed: {
      x: "100%",
      transition: {
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const handleScrollTo = (sectionId: string) => {
    if (window.location.pathname !== "/") {
      localStorage.setItem("scrollTo", sectionId);
      window.location.href = "/";
      return;
    }

    // Close the mobile menu first
    onClose();

    // Add a small delay to allow for any animations/transitions
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={menuVariants}
      className="fixed inset-0 z-50 md:hidden bg-black"
    >
      <nav className="flex flex-col h-full">
        {/* Header with Logo */}
        <div className="flex items-center justify-between p-6 border-b border-white/10">
          <Link to="/" className="flex items-center space-x-3" onClick={onClose}>
            <div className="relative w-8 h-8 flex items-center justify-center">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-orange-500 rounded-xl opacity-20 blur-sm" />
              <PenLine className="w-5 h-5 text-white relative z-10" strokeWidth={2.5} />
            </div>
            <span className="text-white font-bold text-xl tracking-tight">
              Annotate
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">.club</span>
            </span>
          </Link>
          <Button variant="ghost" size="icon" onClick={onClose} className="h-8 w-8 text-gray-400 hover:text-white">
            <X className="h-5 w-5" />
          </Button>
        </div>

        {/* Navigation Links */}
        <div className="flex-1 flex flex-col justify-center px-4">
          <div className="space-y-6">
            <Link
              to="/"
              className="block px-4 py-3 text-gray-200 hover:text-white transition-colors text-lg font-medium rounded-lg hover:bg-white/5"
              onClick={onClose}
            >
              Home
            </Link>
            {!hideUpload && (
              <>
                <button
                  onClick={() => handleScrollTo("built-for-readers")}
                  className="w-full text-left px-4 py-3 text-gray-200 hover:text-white transition-colors text-lg font-medium rounded-lg hover:bg-white/5"
                >
                  Features
                </button>
                <button
                  onClick={() => handleScrollTo("research-backed")}
                  className="w-full text-left px-4 py-3 text-gray-200 hover:text-white transition-colors text-lg font-medium rounded-lg hover:bg-white/5"
                >
                  Research
                </button>
                <button
                  onClick={() => {
                    onClose();
                    navigate("/pricing");
                  }}
                  className="w-full text-left px-4 py-3 text-gray-200 hover:text-white transition-colors text-lg font-medium rounded-lg hover:bg-white/5"
                >
                  Pricing
                </button>
              </>
            )}
          </div>
        </div>

        {/* Footer with CTA */}
        <div className="p-6 border-t border-white/10">
          {hideUpload ? (
            <Link to="/bookshelf" onClick={onClose}>
              <Button className="w-full bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600 text-white py-6 text-lg">
                Back to Library
              </Button>
            </Link>
          ) : (
            <Link to="/bookshelf" onClick={onClose}>
              <Button className="w-full bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600 text-white py-6 text-lg">
                Try Demo
              </Button>
            </Link>
          )}
        </div>
      </nav>
    </motion.div>
  );
}
