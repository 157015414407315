import { get, set, del, clear, entries } from "idb-keyval";
import type { StoredBook, BookHighlight, BookNote } from "@/types/book";

const BOOK_PREFIX = "book:";
const HIGHLIGHT_PREFIX = "highlight:";
const POSITION_PREFIX = "position:";
const NOTE_PREFIX = "note:";

export const storage = {
  // Book operations
  async getBook(fileName: string): Promise<StoredBook | undefined> {
    return get(`${BOOK_PREFIX}${fileName}`);
  },

  async getAllBooks(): Promise<StoredBook[]> {
    const allEntries = await entries();
    const books = allEntries
      .filter(([key]) => key.toString().startsWith(BOOK_PREFIX))
      .map(([_, value]) => value as StoredBook);
    return books.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
  },

  async storeBook(book: StoredBook): Promise<void> {
    await set(`${BOOK_PREFIX}${book.fileName}`, book);
  },

  async deleteBook(fileName: string): Promise<void> {
    // Delete the book
    await del(`${BOOK_PREFIX}${fileName}`);

    // Delete all associated highlights
    const highlights = await this.getHighlights(fileName);
    for (const highlight of highlights) {
      await this.deleteHighlight(fileName, highlight.id);
    }

    // Delete the reading position
    await del(`${POSITION_PREFIX}${fileName}`);
  },

  // Highlight operations
  async getHighlights(bookId: string): Promise<BookHighlight[]> {
    try {
      const allEntries = await entries();
      const highlights = allEntries
        .filter(([key]) => {
          const keyStr = key.toString();
          return keyStr.startsWith(HIGHLIGHT_PREFIX) && keyStr.includes(bookId);
        })
        .map(([_, value]) => value as BookHighlight);

      return highlights.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    } catch (error) {
      console.error("Failed to get highlights:", error);
      return [];
    }
  },

  async addHighlight(highlight: BookHighlight): Promise<void> {
    await set(`${HIGHLIGHT_PREFIX}${highlight.bookId}:${highlight.id}`, highlight);
  },

  async updateHighlight(highlight: BookHighlight): Promise<void> {
    await set(`${HIGHLIGHT_PREFIX}${highlight.bookId}:${highlight.id}`, highlight);
  },

  async deleteHighlight(bookId: string, highlightId: string): Promise<void> {
    await del(`${HIGHLIGHT_PREFIX}${bookId}:${highlightId}`);

    // Delete associated notes
    const notes = await this.getNotes(bookId, highlightId);
    for (const note of notes) {
      await this.deleteNote(bookId, note.id);
    }
  },

  // Note operations
  async getNotes(bookId: string, highlightId?: string): Promise<BookNote[]> {
    try {
      const allEntries = await entries();
      const notes = allEntries
        .filter(([key]) => {
          const keyStr = key.toString();
          return (
            keyStr.startsWith(NOTE_PREFIX) && keyStr.includes(bookId) && (!highlightId || keyStr.includes(highlightId))
          );
        })
        .map(([_, value]) => value as BookNote);

      return notes.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    } catch (error) {
      console.error("Failed to get notes:", error);
      return [];
    }
  },

  async addNote(note: BookNote): Promise<void> {
    await set(`${NOTE_PREFIX}${note.bookId}:${note.highlightId}:${note.id}`, note);
  },

  async updateNote(note: BookNote): Promise<void> {
    await set(`${NOTE_PREFIX}${note.bookId}:${note.highlightId}:${note.id}`, note);
  },

  async deleteNote(bookId: string, noteId: string): Promise<void> {
    await del(`${NOTE_PREFIX}${bookId}:${noteId}`);
  },

  // Reading position operations
  async savePosition(fileName: string, cfi: string): Promise<void> {
    await set(`${POSITION_PREFIX}${fileName}`, {
      cfi,
      timestamp: new Date(),
    });
  },

  async getPosition(fileName: string): Promise<{ cfi: string; timestamp: Date } | undefined> {
    return get(`${POSITION_PREFIX}${fileName}`);
  },

  // Storage management
  async clearAll(): Promise<void> {
    await clear();
  },
};
