"use client";

import { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "@/components/navbar";
import { storage } from "@/lib/storage";

const ReaderComponent = lazy(() => import("../components/reader-component"));

interface BookContent {
  content: ArrayBuffer;
  fileName: string;
}

export default function Reader() {
  const [currentBook, setCurrentBook] = useState<BookContent | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadBook = async () => {
      const lastAccessed = localStorage.getItem("lastAccessedBook");
      if (!lastAccessed) {
        navigate("/bookshelf");
        return;
      }

      try {
        const book = await storage.getBook(lastAccessed);
        if (!book) {
          navigate("/bookshelf");
          return;
        }

        setCurrentBook({
          content: book.content,
          fileName: book.fileName,
        });
      } catch (error) {
        console.error("Failed to load book:", error);
        navigate("/bookshelf");
      }
    };

    loadBook();
  }, [navigate]);

  if (!currentBook) {
    return (
      <div className="flex h-screen items-center justify-center bg-black/[0.98]">
        <div className="animate-pulse text-center">
          <div className="h-8 w-32 bg-gradient-to-r from-blue-600/20 to-orange-500/20 rounded mb-4"></div>
          <div className="h-4 w-48 bg-gradient-to-r from-blue-600/10 to-orange-500/10 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black/[0.98] relative">
      <div className="fixed top-0 left-0 right-0 z-50">
        <Navbar showBookshelf />
      </div>
      <Suspense
        fallback={
          <div className="flex h-screen items-center justify-center bg-black/[0.98]">
            <div className="animate-pulse text-center">
              <div className="h-8 w-32 bg-gradient-to-r from-blue-600/20 to-orange-500/20 rounded mb-4"></div>
              <div className="h-4 w-48 bg-gradient-to-r from-blue-600/10 to-orange-500/10 rounded"></div>
            </div>
          </div>
        }
      >
        <ReaderComponent bookData={currentBook.content} bookId={currentBook.fileName} />
      </Suspense>
    </div>
  );
}
