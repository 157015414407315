"use client";

import { lazy, Suspense, useState } from "react";
import Hero from "../components/hero";
import Navbar from "../components/navbar";
import Features from "../components/features";
import Research from "../components/research";
import DemoSection from "../components/demo-section";
import { HighlightTrail } from "../components/ui/highlight-trail";

const SparklesCore = lazy(() => import("../components/sparkles").then((mod) => ({ default: mod.SparklesCore })));

export default function Home() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <main className="min-h-screen bg-black/[0.98] antialiased bg-grid relative overflow-hidden custom-cursor">
      <div className="h-full w-full absolute inset-0 z-0">
        <Suspense fallback={null}>
          <SparklesCore
            id="tsparticlesfullpage"
            background="transparent"
            minSize={0.8}
            maxSize={1.8}
            particleDensity={120}
            className="w-full h-full"
          />
        </Suspense>
      </div>

      <HighlightTrail />

      <div className="relative z-10">
        <Navbar onMobileMenuChange={setIsMobileMenuOpen} />
        <div className={isMobileMenuOpen ? "hidden md:block" : "block"}>
          <Hero />
          <Features />
          <Research />
          <DemoSection />
        </div>
      </div>
    </main>
  );
}
