"use client";

import { motion } from "framer-motion";
import { Lightbulb, Trophy, TrendingUp } from "lucide-react";
import { useAvailableSpace } from "@/hooks/use-available-space";
import { LeftFeatureIllustrations, RightFeatureIllustrations } from "./research-illustrations";

export default function Research() {
  const hasSpace = useAvailableSpace();
  const cards = [
    {
      icon: Lightbulb,
      title: "Active Learning",
      description: (
        <>
          Research shows 43% better retention through collaborative annotation and mind mapping.
          <br />
          <a
            href="https://link.springer.com/article/10.1007/s12564-022-09746-9"
            className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500 hover:from-blue-500 hover:to-orange-600 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            View study →
          </a>
        </>
      ),
    },
    {
      icon: Trophy,
      title: "Proven Results",
      description:
        "Students using collaborative annotation showed 2.5x better comprehension scores in a 2023 Asia Pacific Education Review study.",
    },
    {
      icon: TrendingUp,
      title: "Measured Growth",
      description:
        "87% of users reported improved understanding and retention when using active note-taking with peer discussion.",
    },
  ];

  return (
    <section id="research-backed" className="border-t border-white/10 relative">
      {hasSpace && (
        <>
          <LeftFeatureIllustrations />
          <RightFeatureIllustrations />
        </>
      )}
      <div className="container py-24 md:py-32 px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="mx-auto max-w-[58rem] text-center mb-16"
        >
          <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-blue-500 to-orange-500 mb-4">
            Research-Backed Approach
          </h2>
          <p className="text-gray-400 sm:text-lg">
            Every feature is grounded in proven educational research and cognitive science
          </p>
        </motion.div>

        {/* Center Content */}
        <div className="mx-auto max-w-[58rem]">
          <div className="grid gap-8 md:grid-cols-3">
            {cards.map((card, index) => (
              <motion.div
                key={card.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8 hover:bg-gradient-to-r hover:from-blue-500/5 hover:to-orange-500/5 transition-all duration-300"
              >
                <div className="flex flex-col items-center text-center space-y-4">
                  <div className="flex justify-center items-center">
                    <card.icon className="h-8 w-8 text-white" strokeWidth={1.5} />
                  </div>
                  <h3 className="text-xl font-bold text-white">{card.title}</h3>
                  <p className="text-gray-400">{card.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
