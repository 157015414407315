import { lazy, Suspense, useState, useEffect } from "react";
import Navbar from "../components/navbar";
import { motion } from "framer-motion";
import { Check, Coffee, Gift, Bot, Coins, CreditCard } from "lucide-react";
import { HighlightTrail } from "../components/ui/highlight-trail";

const SparklesCore = lazy(() => import("../components/sparkles").then((mod) => ({ default: mod.SparklesCore })));

export default function Pricing() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      id="Pricing"
      className="min-h-screen bg-black/[0.98] antialiased bg-grid text-white relative overflow-hidden custom-cursor"
    >
      <div className="h-full w-full absolute inset-0 z-0">
        <HighlightTrail />
        <Suspense fallback={null}>
          <SparklesCore
            id="tsparticlesfullpage"
            background="transparent"
            minSize={0.8}
            maxSize={1.8}
            particleDensity={120}
            className="w-full h-full"
          />
        </Suspense>
      </div>

      <div className="relative z-10">
        <Navbar onMobileMenuChange={setIsMobileMenuOpen} />
        <div className={isMobileMenuOpen ? "hidden md:block" : "block"}>
          <div className="container mx-auto px-6 py-32" id="pricing">
            <div className="max-w-4xl mx-auto space-y-24">
              {/* Hero Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center space-y-8"
              >
                <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">
                  Pricing:{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                    It's Free. Always.
                  </span>
                </h1>
                <p className="text-xl text-gray-400 max-w-2xl mx-auto">
                  Yup, you read that right - highlight and make note 😉 (sorry not sorry 🙈) <br />
                  Annotate is 100% free, and it will always be free. No hidden fees, no sneaky paywalls, no "upgrade to
                  premium" nonsense. Just you, your books, and a better way to read.
                </p>
              </motion.div>

              {/* Why Free Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8"
              >
                <div className="flex items-start gap-4">
                  <div className="p-3 rounded-lg bg-blue-500/10 border border-blue-500/20">
                    <Gift className="w-6 h-6 text-blue-400" />
                  </div>
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                      Why Free?
                    </h2>
                    <p className="text-gray-400">
                      Because learning should always be free. Reading is fun, thinking is fun, and collaborating with
                      others? Even more fun. Set goals, learn together, hold each other accountable—education isn't a
                      product, it's an experience, and we're here to make it better.
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Cost Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8"
              >
                <div className="flex items-start gap-4">
                  <div className="p-3 rounded-lg bg-orange-500/10 border border-orange-500/20">
                    <CreditCard className="w-6 h-6 text-orange-400" />
                  </div>
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                      But Wait… Doesn't This Cost Money?
                    </h2>
                    <p className="text-gray-400">
                      Yep. Servers cost money. Development takes time. But here's the thing: we build things because we
                      love to build. That's what developers do. And if companies like Apple and Amazon (who love
                      charging for everything) aren't making you pay to use their e-book readers, why on earth would we?
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* AI Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="space-y-8"
              >
                <div className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8">
                  <div className="flex items-start gap-4">
                    <div className="p-3 rounded-lg bg-blue-500/10 border border-blue-500/20">
                      <Bot className="w-6 h-6 text-blue-400" />
                    </div>
                    <div className="space-y-4">
                      <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                        AI & The Future of Learning
                      </h2>
                      <p className="text-gray-400">
                        At some point, we might introduce AI-powered features—things like smart summarization,
                        personalized reading plans, or AI-assisted note-taking. Stuff that makes learning even more
                        efficient. But AI costs money, and we're not fans of charging for things we don't get charged
                        for.
                      </p>
                      <div className="space-y-4 mt-6">
                        <div className="flex items-start gap-2">
                          <Check className="w-5 h-5 text-blue-500 mt-0.5" />
                          <span className="text-gray-400">
                            If you don't need AI, Annotate will always be free for you.
                          </span>
                        </div>
                        <div className="flex items-start gap-2">
                          <Coins className="w-5 h-5 text-orange-500 mt-0.5" />
                          <span className="text-gray-400">
                            If we do add premium AI features, they'll never cost more than $1/month.
                          </span>
                        </div>
                      </div>
                      <p className="text-gray-400 mt-4">
                        That's it. No upsells, no tricks. Just an option for those who want extra superpowers.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Support Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8"
              >
                <div className="flex items-start gap-4">
                  <div className="p-3 rounded-lg bg-orange-500/10 border border-orange-500/20">
                    <Coffee className="w-6 h-6 text-orange-400" />
                  </div>
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
                      Still Want to Support Us?
                    </h2>
                    <p className="text-gray-400">
                      We appreciate you! But we're not taking your money—not yet, and maybe not ever. Once Annotate is
                      running smoothly and actually making a difference in your life, then maybe we'll say, "Hey, if you
                      want, buy us a coffee." But not before. And definitely never as a requirement.
                    </p>
                    <p className="text-gray-400">So go ahead—read, annotate, collaborate. No wallets needed. 🚀</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
