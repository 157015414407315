import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { BookOpen, Check } from "lucide-react";
import { Link } from "react-router-dom";
import { FloatingThread } from "./floating-thread";
import { useAvailableSpace, HERO_CONTENT_SELECTOR } from "@/hooks/use-available-space";

export default function DemoSection() {
  const hasHeroSpace = useAvailableSpace(HERO_CONTENT_SELECTOR);

  return (
    <section className="border-t border-white/10">
      <div className="container py-24 md:py-32 px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="mx-auto max-w-[58rem] text-center"
        >
          <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500 mb-6">
            Try the Demo
          </h2>
          <p className="text-gray-400 sm:text-lg mb-8 max-w-2xl mx-auto">
            Experience ANNOTATE.CLUB firsthand with our interactive demo. Upload your EPUBs, try out annotations, and
            explore the basic features.
          </p>

          {/* Mobile/Tablet Thread Preview */}
          {!hasHeroSpace && (
            <div className="mb-8">
              <FloatingThread />
            </div>
          )}

          <div className="grid gap-4 md:grid-cols-2 max-w-xl mx-auto mb-8">
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-blue-500 mt-0.5" />
              <span className="text-gray-400 text-left">Local storage for your books</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-blue-500 mt-0.5" />
              <span className="text-gray-400 text-left">Basic annotation system</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-blue-500 mt-0.5" />
              <span className="text-gray-400 text-left">Chapter navigation</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-blue-500 mt-0.5" />
              <span className="text-gray-400 text-left">Text size customization</span>
            </div>
          </div>

          <Link to="/reader">
            <Button
              size="lg"
              className="bg-gradient-to-r from-blue-600 to-orange-500 hover:from-blue-700 hover:to-orange-600 text-white px-8"
            >
              <BookOpen className="mr-2 h-5 w-5" />
              Launch Demo
            </Button>
          </Link>

          <p className="text-sm text-gray-500 mt-4">No sign-up required • v0.2 Alpha</p>
        </motion.div>
      </div>
    </section>
  );
}
