"use client";

import { motion } from "framer-motion";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Link2, ThumbsUp, CornerDownRight } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useInView } from "framer-motion";
import { useRef, useState, useEffect } from "react";

const mobileThread = {
  id: uuidv4(),
  messages: [
    {
      id: uuidv4(),
      user: {
        name: "Sarah Chen",
        avatar: "https://v0.dev/placeholder-avatar.jpg",
        initials: "SC",
        gradientFrom: "from-blue-400",
        gradientTo: "to-purple-400",
      },
      content: {
        book: "Deep Work",
        quote:
          "The ability to perform deep work is becoming increasingly rare at exactly the same time it is becoming increasingly valuable in our economy.",
        page: "28",
        note: "This perfectly explains why focused reading and annotation is so crucial in today's distracted world 📚",
        reactions: 6,
      },
    },
    {
      id: uuidv4(),
      user: {
        name: "Marcus Johnson",
        avatar: "https://v0.dev/placeholder-avatar-2.jpg",
        initials: "MJ",
        gradientFrom: "from-green-400",
        gradientTo: "to-emerald-400",
      },
      content: {
        note: "Agreed! I've found that dedicated reading blocks with focused annotation significantly improve my retention.",
        reactions: 4,
        replyTo: "Sarah Chen",
      },
    },
    {
      id: uuidv4(),
      user: {
        name: "Sarah Chen",
        avatar: "https://v0.dev/placeholder-avatar.jpg",
        initials: "SC",
        gradientFrom: "from-blue-400",
        gradientTo: "to-purple-400",
      },
      content: {
        note: "Exactly! And using the highlight feature here helps me stay focused and track key insights over time 🎯",
        reactions: 5,
        replyTo: "Marcus Johnson",
      },
    },
  ],
};

export function FloatingThread() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (isInView) {
      setShouldAnimate(true);
    }
  }, [isInView]);

  const renderMessageContent = (content: {
    book?: string;
    quote?: string;
    page?: string;
    note?: string;
    reactions?: number;
    replyTo?: string;
  }) => {
    return (
      <div className="space-y-2">
        {content.replyTo && (
          <div className="flex items-center gap-2 text-[10px] text-white/40 mb-1">
            <CornerDownRight className="w-3 h-3" />
            <span>Replying to {content.replyTo}</span>
          </div>
        )}
        {content.book && (
          <div className="flex items-center gap-2 text-[10px] text-white/50">
            <Link2 className="w-3 h-3" />
            <span className="hover:text-white/70 transition-colors cursor-pointer">
              {content.book} {content.page && `p.${content.page}`}
            </span>
          </div>
        )}
        {content.quote && <div className="pl-2 border-l-2 border-white/10 italic text-white/60">"{content.quote}"</div>}
        <div className="space-y-1.5">
          {content.note && <div className="text-white/70">{content.note}</div>}
          {content.reactions && (
            <div className="flex items-center gap-1.5">
              <div className="flex items-center gap-1 text-[10px] text-white/50 bg-white/5 px-2 py-1 rounded-full">
                <ThumbsUp className="w-3 h-3" />
                <span>{content.reactions}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-w-md mx-auto" ref={ref}>
      <div className="space-y-2">
        {mobileThread.messages.map((message, messageIndex) => (
          <motion.div
            key={message.id}
            initial={{ opacity: 0, y: 20 }}
            animate={shouldAnimate ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{
              delay: shouldAnimate ? messageIndex * 0.5 : 0,
              duration: 0.8,
              type: "spring",
              stiffness: 80,
              damping: 15,
            }}
            className={message.content.replyTo ? "ml-4" : ""}
          >
            <div className="flex gap-3 p-3">
              <motion.div
                initial={{ scale: 0 }}
                animate={shouldAnimate ? { scale: 1 } : { scale: 0 }}
                transition={{
                  delay: shouldAnimate ? messageIndex * 0.5 + 0.2 : 0,
                  type: "spring",
                  stiffness: 200,
                  damping: 15,
                }}
              >
                <Avatar className="w-7 h-7 border border-white/5 ring-1 ring-offset-2 ring-offset-black/50 ring-white/10">
                  <AvatarImage src={message.user.avatar} />
                  <AvatarFallback
                    className={`bg-gradient-to-r ${message.user.gradientFrom} ${message.user.gradientTo} text-white/90 text-xs font-medium opacity-80`}
                  >
                    {message.user.initials}
                  </AvatarFallback>
                </Avatar>
              </motion.div>
              <div className="flex-1 space-y-1">
                <motion.div
                  className="flex items-center gap-2"
                  initial={{ opacity: 0 }}
                  animate={shouldAnimate ? { opacity: 1 } : { opacity: 0 }}
                  transition={{ delay: shouldAnimate ? messageIndex * 0.5 + 0.3 : 0 }}
                >
                  <span className="text-xs font-medium text-white/80">{message.user.name}</span>
                  <span className="text-[10px] text-white/40">just now</span>
                </motion.div>
                <motion.div
                  className="p-2.5 rounded-lg bg-black/40 border border-white/[0.05] text-white/70 backdrop-blur-md
                    bg-gradient-to-r from-white/[0.02] to-white/[0.01] text-xs leading-relaxed"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={shouldAnimate ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.95 }}
                  transition={{ delay: shouldAnimate ? messageIndex * 0.5 + 0.4 : 0 }}
                >
                  {renderMessageContent(message.content)}
                </motion.div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
