"use client";

import { motion } from "framer-motion";
import { Users2, GitFork, Scroll, BrainCircuit, ShieldCheck, MessagesSquare } from "lucide-react";

export default function Features() {
  const features = [
    {
      name: "Real-time Collaboration",
      description: "Read and annotate together in real-time. Create reading groups and share insights instantly.",
      icon: Users2,
      badge: "Soon",
    },
    {
      name: "Knowledge Mapping",
      description: "Create bi-directional links between books and notes. Build your personal knowledge graph.",
      icon: GitFork,
      badge: "Soon",
    },
    {
      name: "EPUB Reader",
      description:
        "Read your books with a clean, customizable interface. Adjust text size and navigate chapters easily.",
      icon: Scroll,
      badge: "Available",
    },
    {
      name: "Smart Annotations",
      description: "Highlight, take notes, and organize your thoughts. Perfect for research and study groups.",
      icon: BrainCircuit,
      badge: "Available",
    },
    {
      name: "Privacy Controls",
      description:
        "Choose what to share. Keep your books and notes private or share them with specific groups and organizations.",
      icon: ShieldCheck,
      badge: "Available",
    },
    {
      name: "Social Reading",
      description: "Join communities based on your interests. Share and discover annotations from like-minded readers.",
      icon: MessagesSquare,
      badge: "Soon",
    },
  ];

  return (
    <section id="built-for-readers" className="py-24 md:py-32 px-6 relative">
      <div className="container mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="mx-auto max-w-[58rem] text-center mb-16"
        >
          <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-5xl mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-orange-500">
            Built for Non-Fiction Readers
          </h2>
          <p className="text-gray-400 sm:text-lg">
            Transform how you read, learn, and collaborate with powerful features designed for serious readers.
          </p>
        </motion.div>

        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <motion.div
              key={feature.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="relative overflow-hidden rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm p-8 hover:bg-gradient-to-r hover:from-blue-500/5 hover:to-orange-500/5 transition-all duration-300"
            >
              <div className="flex flex-col gap-4">
                <div className="flex justify-center items-center">
                  <feature.icon className="h-8 w-8 text-white" strokeWidth={1.5} />
                </div>
                <div className="space-y-2">
                  <div className="flex items-center justify-between gap-2">
                    <h3 className="font-bold text-white">{feature.name}</h3>
                    <span
                      className={`text-xs px-3 py-1 rounded-full font-semibold inline-flex items-center justify-center ${
                        feature.badge === "Available"
                          ? "bg-gradient-to-r from-blue-500/15 to-orange-500/15 text-blue-400 border border-blue-500/20"
                          : "bg-gradient-to-r from-orange-500/15 to-red-500/15 text-orange-400 border border-orange-500/20"
                      }`}
                    >
                      {feature.badge}
                    </span>
                  </div>
                  <p className="text-gray-400">{feature.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
